import Risks from 'assets/icons/risks'
import Button, { ButtonVariant } from 'components/button'
import Modal from 'components/modal'
import Typography, { Variant } from 'components/typography'

const ItemRemovalWarningDark = ({
  showModal,
  setShowModal,
  classes,
  confirmationMessage,
  doConfirmationAction,
  modalId,
  confirmationCtaText = 'Exit',
}: ItemRemovalWarningDarkProps) => {
  const handleModalClose = () => {
    setShowModal(false)
  }

  const handleDeleteItem = () => {
    doConfirmationAction()
  }

  return (
    <Modal
      title={''}
      isOpen={showModal}
      onClose={handleModalClose}
      modalId={modalId}
      className={`!p-6 h-full [&>div:first-child]:hidden  ${classes?.modal?.root || ''}`}
      containerClassName={`w-[624px] !bg-[#212048] shadow-[0px_0px_80px_0px_rgba(59,130,246,0.24)] ${
        classes?.modal?.container || ''
      }`}>
      <div className="flex flex-col  gap-6 justify-between h-full w-full">
        <div className="self-center flex flex-col items-center gap-1.5">
          <Risks className="w-[18px] h-[18px]" pathClassName="stroke-red-500" />
          <Typography type="semibold" className="text-gray-300" variant={Variant.Callout}>
            {confirmationMessage}
          </Typography>
        </div>
        <div className="flex w-full items-center justify-between gap-3">
          <Button
            onClick={handleModalClose}
            className="!bg-[rgba(135,157,255,0.25)] hover:!bg-[rgba(135,157,255,0.75)] !border-gray-500 !text-gray-300"
            variant={ButtonVariant.Secondary}>
            Cancel
          </Button>
          <Button className="!bg-indigo-400 hover:!bg-indigo-300" onClick={handleDeleteItem}>
            {confirmationCtaText}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

interface ItemRemovalWarningDarkProps {
  showModal: boolean
  confirmationMessage: string | React.ReactNode
  doConfirmationAction: () => void
  confirmationCtaText?: string
  setShowModal: (x: boolean) => void
  classes?: { modal?: { root?: string; container?: string } }
  modalId?: string
}

export default ItemRemovalWarningDark
