import ItemRemovalWarning from 'components/itemRemovalWarning'
import ItemRemovalWarningDark from 'components/itemRemovalWarning/ItemRemovalWarningDark'
import React, { useCallback, useState } from 'react'

const createPromise = (): [Promise<boolean>, (value: boolean) => void] => {
  let resolver: ((value: boolean) => void) | null = null
  const promise = new Promise<boolean>(resolve => {
    resolver = resolve
  })
  if (!resolver) throw new Error('Resolver should be initialized')
  return [promise, resolver]
}

const useConfirmationModal = (modalId?: string) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isDark, setIsDark] = useState(false)
  const [title, setTitle] = useState<string | undefined>()
  const [ctaText, setCtaText] = useState<string>()
  const [label, setLabel] = useState<string | React.ReactNode>('')
  const [resolver, setResolver] = useState<((value: boolean) => void) | null>(null)

  const getConfirmation = useCallback(
    async (text: string | React.ReactNode, title?: string, confirmationCtaText?: string) => {
      setTitle(title)
      setIsDark(false)
      setLabel(text)
      setIsOpen(true)
      setCtaText(confirmationCtaText || undefined)
      const [promise, resolve] = createPromise()
      setResolver(() => resolve)
      return promise
    },
    []
  )

  const getConfirmationDark = useCallback(async (text: string | React.ReactNode, confirmationCtaText?: string) => {
    setIsDark(true)
    setLabel(text)
    setIsOpen(true)
    setCtaText(confirmationCtaText || undefined)
    const [promise, resolve] = createPromise()
    setResolver(() => resolve)
    return promise
  }, [])

  const onClick = useCallback(
    (status: boolean) => {
      setIsOpen(false)
      if (resolver) resolver(status)
    },
    [resolver]
  )

  const ConfirmationModal = useCallback(
    () =>
      !isDark ? (
        <ItemRemovalWarning
          showModal={isOpen}
          title={title}
          setShowModal={() => onClick(false)}
          confirmationMessage={label}
          doConfirmationAction={() => onClick(true)}
          modalId={modalId}
          confirmationCtaText={ctaText}
        />
      ) : (
        <ItemRemovalWarningDark
          showModal={isOpen}
          setShowModal={() => onClick(false)}
          confirmationMessage={label}
          doConfirmationAction={() => onClick(true)}
          modalId={modalId}
          confirmationCtaText={ctaText}
        />
      ),
    [ctaText, isDark, isOpen, label, modalId, onClick, title]
  )

  return { getConfirmation, getConfirmationDark, ConfirmationModal, resolve: onClick, isOpen }
}

export default useConfirmationModal
