import Delete from 'assets/icons/delete'
import Edit from 'assets/icons/edit'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import ItemRemovalWarning from 'components/itemRemovalWarning'
import EditorModal from 'components/modal/components/editorModal'
import { EditorRef } from 'components/tinyMceEditor/types'
import useConfirmationModal from 'hooks/useConfirmationModal'
import React, { useCallback, useRef, useState } from 'react'
import { Control, FieldValues, Path, useController, useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'

type HTMLViewEditProps<T> = {
  onSave?: () => Promise<unknown> | void
  className?: string
  label?: string | React.ReactNode
  id: Path<T & FieldValues>
  control: Control<T & FieldValues>
  handleDelete?: (item: Path<T & FieldValues>) => void
  saveButtonText?: string
  saveButtonIcon?: IconsType
  leftGroupButtons?: string | string[]
  disableSaveButton?: boolean
}

function HTMLViewEdit<T>({
  className = 'ml-auto',
  label,
  id,
  control,
  handleDelete,
  saveButtonText = 'Done',
  saveButtonIcon,
  onSave,
  leftGroupButtons,
  disableSaveButton,
}: HTMLViewEditProps<T>) {
  const editorRef = useRef<EditorRef>(null)
  const { field, fieldState } = useController({ name: id, control })
  const [fieldValueWhenOpening, setFieldValueWhenOpening] = useState('')
  const value = useWatch({ control, name: id })
  const [showModal, setShowModal] = useState(false)
  const [saving, setSaving] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const { getConfirmationDark, ConfirmationModal } = useConfirmationModal()

  const handleConfirmationToggle = useCallback(() => {
    setShowConfirmationModal(o => !o)
  }, [])

  const handleModalClose = useCallback(async () => {
    if (fieldValueWhenOpening !== value && !(await getConfirmationDark('Changes will be discarded on exit', 'Exit'))) {
      return
    }
    field.onChange(fieldValueWhenOpening, {
      shouldValidate: true,
      shouldDirty: false,
    })
    setShowModal(false)
  }, [field, fieldValueWhenOpening, getConfirmationDark, value])

  const handleEditButton = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      setShowModal(true)
      setFieldValueWhenOpening(value)
    },
    [value]
  )

  const handleSaveOrEdit = useCallback(async () => {
    if (value || (!value && typeof handleDelete != 'function')) {
      try {
        if (onSave) {
          setSaving(true)
          const result = await onSave()
          if (result instanceof Error) {
            throw result
          }
        }
        setShowModal(false)
      } catch (error) {
        toast.error(String(error))
      } finally {
        setSaving(false)
      }
    } else {
      handleConfirmationToggle()
    }
  }, [handleConfirmationToggle, handleDelete, onSave, value])

  const handleDeleteClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      handleConfirmationToggle()
    },
    [handleConfirmationToggle]
  )

  const html = value

  return (
    <div className={cx('flex gap-4', className)}>
      <div
        onClick={handleEditButton}
        className="cursor-pointer flex items-center justify-center rounded-full bg-blue-50 border border-solid border-blue-200 shrink-0 h-8 w-8">
        <Edit pathClassName="stroke-blue-900" className="w-4 h-4" />
      </div>
      {!!html && typeof handleDelete == 'function' && (
        <div
          onClick={handleDeleteClick}
          className="cursor-pointer flex items-center justify-center rounded-full bg-orange-50 border border-solid border-orange-200 shrink-0 h-8 w-8">
          <Delete pathClassName="stroke-orange-600" className="w-4 h-4" />
        </div>
      )}
      <ItemRemovalWarning
        showModal={showConfirmationModal}
        setShowModal={handleConfirmationToggle}
        confirmationMessage={`Are you sure you want to delete ${label}?`}
        doConfirmationAction={() => {
          handleConfirmationToggle()
          handleDelete && handleDelete(id)
          showModal && setShowModal(false)
        }}
      />
      <EditorModal
        editorRef={editorRef}
        isOpen={showModal}
        onClose={handleModalClose}
        control={control}
        name={id}
        onSave={handleSaveOrEdit}
        saveButtonText={saveButtonText}
        saveButtonIcon={saveButtonIcon}
        loading={saving}
        leftGroupButtons={leftGroupButtons}
        placeholder="Start typing..."
        disableSaveButton={saving || !fieldState.isDirty || disableSaveButton}
      />
      <ConfirmationModal />
    </div>
  )
}

export default HTMLViewEdit
