import { Dialog, Transition } from '@headlessui/react'
import cx from 'classnames'
import { Fragment, useEffect, useState } from 'react'

import DrawerHeader from './components/drawerHeader/DrawerHeader'

const Drawer = ({
  isOpen,
  onClose,
  children,
  className,
  title,
  titleClassName,
  containerClassName,
  slider = false,
  closeIconClassName,
  closeIconPathClassName = 'fill-blue-900',
}: CustomDrawerProps) => {
  const [delayedOpen, setDelayedOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => setDelayedOpen(true), 0)
      return () => clearTimeout(timer)
    } else {
      setDelayedOpen(false)
    }
  }, [isOpen])

  return (
    <Transition appear show={delayedOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 commonIntegralModalClass" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div
            className={cx('fixed inset-0', {
              'bg-sliderBackdropGradient': slider,
              'bg-drawerBackdropGradient': !slider,
            })}
            onClick={onClose}
          />
        </Transition.Child>
        <Transition.Child
          as={'div'}
          enter="ease-out duration-500"
          enterFrom={cx('opacity-0', slider ? 'translate-y-full' : 'translate-x-full')}
          className={cx(
            'fixed',
            slider
              ? 'shadow-[-4px_-12px_30px_5px_rgba(24,77,195,0.12)] right-0 left-0 bg-blue-50 bottom-0 rounded-lg  m-6 min-h-[30%] h-[588px]  max-h-[calc(100%-4rem)]'
              : 'h-[calc(100vh-2rem)] right-0 shadow-[-12px_4px_30px_5px_rgba(24,77,195,0.12)] bg-blue-50 top-0 rounded-lg m-4 min-w-[60%] w-[888px] max-w-[calc(100%-2rem)]',
            containerClassName
          )}
          enterTo={cx('opacity-100 scale-100', slider ? 'translate-y-0' : 'translate-x-0')}
          leave="ease-in duration-500"
          leaveFrom={cx('opacity-100 scale-100', slider ? 'translate-y-0' : 'translate-x-0')}
          leaveTo={cx('opacity-0', slider ? 'translate-y-full' : 'translate-x-full')}>
          <div
            className={cx('flex flex-col items-start p-4 h-full', className, {
              'gap-4': title,
            })}>
            <DrawerHeader
              closeIconClassName={closeIconClassName}
              closeIconPathClassName={closeIconPathClassName}
              handleClose={onClose}
              title={title}
              titleClassName={titleClassName}
            />
            <div className="overflow-y-auto flex flex-col gap-4 w-full flex-1 h-full">{children}</div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}

interface CustomDrawerProps {
  children: React.ReactNode
  isOpen: boolean
  onClose: VoidFunction
  title: string | React.ReactNode
  containerClassName?: string
  className?: string
  closeIconClassName?: string
  titleClassName?: string
  closeIconPathClassName?: string
  slider?: boolean
}

export default Drawer
